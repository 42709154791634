import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

export type Markdown = {
  id: string;
  fileAbsolutePath: string;
  html: string;
  frontmatter: {
    title: string;
  };
};

type BlogPostProps = {
  pageContext: {
    node: Markdown;
  };
};

const BlogPost: React.FC<BlogPostProps> = ({ pageContext: { node } }) => (
  <Layout>
    <Seo title={node.frontmatter.title} />
    <h1 className="uk-heading-small uk-heading-divider border-bottom">
      {node.frontmatter.title}
    </h1>
    <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
  </Layout>
);

export default BlogPost;
